import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";
import { Link } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import urls from "../../../../../Utils/URLs";
import StoreOverviewDoThings from "./Components/StoreOverviewDoThings";
import StoreOverviewHome from "./Components/StoreOverviewHome";
import StoreOverviewService from "../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
import StoreOverviewCard from "../../AccountOverviewRelaunch/Scenes/MyStores/Components/Cards/StoreOverviewCard";
import PowerbiEmbed from "../Components/PowerBI/PowerbiEmbed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Prime from '../../../../../../src/designUtils/Storeoverview/icons/PrimeDayReports2.svg';
import DoThingsIcon from '../../../../../../src/assets/img/StoreOverview_Img/StoreOverviewV1/DoThingsIcon.svg'
import ProductsIcon from '../../../../../../src/assets/img/StoreOverview_Img/StoreOverviewV1/ProductsIcon.svg'
import ReportsIcon from '../../../../../../src/assets/img/StoreOverview_Img/StoreOverviewV1/ReportsIcon.svg'
import StoresIcon from '../../../../../../src/assets/img/StoreOverview_Img/StoreOverviewV1/StoresIcon.svg'
import TrendsIcon from '../../../../../../src/assets/img/StoreOverview_Img/StoreOverviewV1/TrendsIcon.svg'
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import No_ratings from "./../../../../../assets/img/StoreOverview_Img/No_rating.svg";
import Visit from "./../../../../../assets/img/StoreOverview_Img/visit.svg";
import Arrow from "./../../../../../assets/img/StoreOverview_Img/arrow.svg";
import Group from "./../../../../../assets/img/StoreOverview_Img/Group.png";
import StarRating from "../Components/Rating/StarRating";
import StoreOverviewReports from "./Components/StoreOverviewReports";
import StoreTabs from "../../AccountOverviewRelaunch/Scenes/MyStores/Components/Nav/StoreTabs";
import TicketListPage from "../../TroubleTickets/TicketListPage";
import StoreOverviewProductPage from "./Components/StoreOverviewProductPage";
import ProductsAlertTicketCreateService from "../../../../../Service/DashboardServices/TroubleTicketServices/ProductsAlertTicketCreateService";
import {
  faHome,
  faStore,
  faCaretDown,
  faCaretRight,
  faBox,
  faTag,
  faList,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import StoreInformationCard from "../Components/StoreOverviewLayout/StoreInformationCard";
import DownArrow from "./../../../../../designUtils/Storeoverview/icons/DownArrow.svg"
import StoreOverviewNewProductPage from "./Components/StoreOverviewNewProductPage";
import PrimeReportPageUi from "./Components/SubComponents/PrimeReportPageUi";
function StoreOverviewPage(props) {
  let { storeIntegerID } = props.match.params;
  const [store, setStore] = useState(props.adProfiles[0]);
  const [storeName, setStoreName] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const history = useHistory();
  const [selectedStore, setSelectedStore] = useState("");
  const [newIntegerId, setNewIntegerId] = useState("");

  const [homePage, setHomePage] = useState(false);
  const [reportpage, setReportpage] = useState(false);
  const [doThings, setDoThings] = useState(true);
  const [productsPage, setProduct] = useState(false);
  const [primeReportPage, setPrimeReportPage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // side bar dropdowns 
  const [selectedDoThings, setSelectedDoThings] = useState([]);
  const [selectedProblemArea, setSelectedProblemArea] = useState([]);
  const [subRequestTypes, setSubRequestTypes] = useState([]);
  const [selectedProblemPathWay, setSelectedProblemPathWay] = useState([]);

  const [currentOption1, setCurrentOption1] = useState()
  const [currentOption2, setCurrentOption2] = useState()
  const [currentOption3, setCurrentOption3] = useState()
  const [currentOption4, setCurrentOption4] = useState()

  const [currentPageLeval1, setCurrentPageLeval1] = useState()
  const [currentPageLeval2, setCurrentPageLeval2] = useState()
  const [currentPageLeval3, setCurrentPageLeval3] = useState()
  const [currentPageLeval4, setCurrentPageLeval4] = useState()

  const SetCurrentOption = (CurrentOption, currentPageLeval) => {
    console.log(CurrentOption)
    console.log(currentPageLeval)
    switch (currentPageLeval) {
      case 1:
        setCurrentOption1(CurrentOption)
        setToggleDothingOpen(true);
        break;
      case 2:
        setCurrentOption2(CurrentOption)
        setToggleProblemAreaOpen(true);
        break;
      case 3:
        setCurrentOption3(CurrentOption)
        setToggleProblemAreaPathwayOpen(true);
        break
      case 4:
        setCurrentOption4(CurrentOption)
        setToggleSubTypeOpen(true);
        break;
      default:
        break;
    }
  }
  // ---------------
  useEffect(() => {
    ProductsAlertTicketCreateService(props.token).then((response) => {
      if (response) {
        console.log('ProductsAlertTicketCreateService')
        console.log(response)
        setSelectedDoThings(response.mainRequestTypes)
        setSelectedProblemArea(response.problemAreaList);
        setSubRequestTypes(response.subRequestTypes);
        setSelectedProblemPathWay(response.problemAreaPathwayList);
      }
    });
  }, [props.token]);
  //--------------
  const handleReport = () => {
    setHomePage(false);
    setDoThings(false);
    setProduct(false);
    setReportpage(true);
    setToggleDothingOpen(false)
    setPrimeReportPage(false)
  };
  const handlePrimeReport = () => {
    setHomePage(false);
    setDoThings(false);
    setProduct(false);
    setReportpage(false);
    setToggleDothingOpen(false)
    setPrimeReportPage(true)
  };
  const renderReportPage = () => (
    <StoreOverviewReports profileId={store.integerID} />
  );

  const handleStoreOverviewPage = () => {
    setHomePage(true);
    setDoThings(false);
    setReportpage(false);
    setProduct(false);
    setToggleDothingOpen(false)
    setPrimeReportPage(false)
  };
  // const showReport = () => {
  //   var table = "Merge1_Adv_Summary_SPAPI_Asin_Total";
  //   var column = "ProfileId";
  //   return (
  //     <PowerbiEmbed
  //       tbl_name={table}
  //       col_name={column}
  //       responseURL={props.StoreOverviewReport}
  //       profileId={store.integerID}
  //     />
  //   );
  // };
  const showReport = () => {
    var table = "newamazonadvertiserprofile";
    var column = "integerID";
    return (
      <PowerbiEmbed
        tbl_name={table}
        col_name={column}
        responseURL={props.StoreOverviewReport}
        profileId={store.integerID}
      />
    );
  };



  const rederStoreOverviewPage = () => {
    return (
      <>
        <Col md={12} className=" pr-0">
          {showReport()}
        </Col>
      </>
    );
  };
  const primeReport = () => {
    var table = "newproductonamazon";
    var column = "IntegerId";

    return (
      // <PowerbiEmbed
      //   tbl_name={table}
      //   col_name={column}
      //   responseURL={props.StoreOverviewPrimeReport}
      //   profileId={store.integerID}
      // />
      <PrimeReportPageUi
        tbl_name={table}
        col_name={column}
        responseURL={props.StoreOverviewPrimeReport}
        profileId={store.integerID}
      />
    );
  }
  const rederPrimeReport = () => {
    return (
      <>
        <Col md={12} className="p-0">
          {primeReport()}
        </Col>
      </>
    )

  }
  const handleDoThings = () => {
    setHomePage(false);
    setDoThings(true);
    setReportpage(false);
    setProduct(false);
    setPrimeReportPage(false)
  };
  const renderDoThingspage = () => {
    return <StoreOverviewDoThings SetCurrentOption={SetCurrentOption} handleProducts={handleProducts} closeMaintype={closeMaintype} closeProblemArea={closeProblemArea} closeProblemAreaPathway={closeProblemAreaPathway} closeSubtype={closeSubtype} />;
  };

  const handleProducts = () => {
    setHomePage(false);
    setDoThings(false);
    setReportpage(false);
    setProduct(true);
    setToggleDothingOpen(false)
    setPrimeReportPage(false)
  };
  const renderProductspage = () => {
    // toggles product selection for later fetching of performance details
    const toggleSelect = (isSelected, asin, isParentProduct) => {
      console.log("In toggleSelect");
      console.log("isParentProduct");
      console.log(isParentProduct);
      console.log("isSelected");
      console.log(isSelected);
      console.log("asin");
      console.log(asin);
      console.log("selectedProducts");
      console.log(selectedProducts);
      if (isSelected) {
        if (isParentProduct) {
          setSelectedProducts([
            { ASIN: asin, isParentProduct: isParentProduct },
          ]); // if parent product selected, clear selectedProducts array and add parent product and dummy child product
        } else {
          if (
            selectedProducts.length > 0 &&
            selectedProducts[0].isParentProduct
          ) {
            // if parent product is the only selection
            setSelectedProducts((prevState) => [
              { ASIN: asin, isParentProduct: isParentProduct },
              ...prevState,
            ]);
          } else if (
            selectedProducts.length > 1 &&
            selectedProducts[1].isParentProduct
          ) {
            // if a child product was already selected
            setSelectedProducts((prevState) => [
              { ASIN: asin, isParentProduct: isParentProduct },
              prevState[1],
            ]);
          } else {
            setSelectedProducts([
              { ASIN: asin, isParentProduct: isParentProduct },
            ]); // no previous selection
          }
        }
      } else {
        if (isParentProduct) {
          setSelectedProducts([]); // clears array if parent product is unselected
        } else {
          setSelectedProducts((prevState) =>
            prevState.filter((product) => product.ASIN !== asin)
          ); // removes product with given ASIN from selectedProducts array
        }
      }
      console.log("selectedProducts");
      console.log(selectedProducts);
    };

    return (
      <>
        {/* <StoreOverviewProductPage
          accessLevel={props.accessLevel}
          storeIntegerID={store.integerID}
          childProducts={props.childProducts}
          storeAccountID={store.details.accountID}
          toggleSelect={toggleSelect}
          selectedProducts={selectedProducts}
        /> */}
        <StoreOverviewNewProductPage
          storeName={storeName}
          accessLevel={props.accessLevel}
          storeIntegerID={store.integerID}
          storeProducts={props.storeProducts}
          childProducts={props.childProducts}
          parentProducts={props.parentProducts}
          storeAccountID={store.details.accountID}
          toggleSelect={toggleSelect}
          selectedProducts={selectedProducts}
        />
      </>
    );
  };
  const renderWaitMessage = () => (
    <Col md="12">
      <h5>Your stores are getting loaded. Thank you for your patience.</h5>
    </Col>
  );
  useEffect(() => {
    if (store) {
      props.dispatch(
        StoreOverviewService(
          props.token,
          store.integerID,
          store.details.accountID
        )
      );
      setStoreName(store.name);
      setNewIntegerId(store.integerID)
      setSelectedStore("/store-overview-v1/myStores/" + store.integerID);
    }
  }, [props.token, store]);
  // useEffect(() => {
  //   if (props.adProfiles.length === 0) {
  //     // Reload the page if the condition is true
  //     console.log('adProfiles not yet fetched')
  //     window.location.reload();
  //   }
  //   setStoreList(props.adProfiles)
  // }, [props.adProfiles]);
  useEffect(() => {
    // redirect to first store in submenu if no store was selected
    if (!storeIntegerID && store) {
      history.push(`${urls.STORE_OVERVIEW_MY_STORES_URL}/${store.integerID}`);
    } else if (props.adProfiles && storeIntegerID) {
      setStore(
        props.adProfiles.find((profile) => profile.integerID == storeIntegerID)
      );
    } else if (props.adProfiles) {
      setStore(props.adProfiles[0]);
    }
  }, [history, store, props.adProfiles, storeIntegerID]);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [toggleDothingOpen, setToggleDothingOpen] = useState(false);
  const [toggleProblemAreaOpen, setToggleProblemAreaOpen] = useState(false);
  const [toggleProblemAreaPathwayOpen, setToggleProblemAreaPathwayOpen] = useState(false);
  const [toggleSubTypeOpen, setToggleSubTypeOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("dothings");

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [storeList, setStoreList] = useState(props.adProfiles);

  const menuItemsStoreList =
    storeList.length === 0
      ? [
        {
          icon: "pe-7s-home",
          label: "My Stores",
          to: urls.STORE_OVERVIEW_MY_STORES_URL,
        },
      ]
      : [
        {
          icon: "pe-7s-home",
          label: "My Stores",
          content: storeList.map((store) => ({
            icon: "pe-7s-home",
            label: `${store.name} (${store.location})`,
            to: `${urls.STORE_OVERVIEW_MY_STORES_URL}/${store.integerID}`,
          })),
        },
      ];

  const handleStoreChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedStore(selectedValue);
    const integerId = selectedValue.split(/(\d+)$/)[1];
    console.log(integerId);
    setStore(
      props.adProfiles.find((profile) => profile.integerID == integerId)
    );
    if (selectedValue) {
      history.push(`${selectedValue}`);
      //window.location.reload();
    }
  };
  const closeMaintype = () => {
    setToggleDothingOpen(false)
  }
  const closeProblemArea = () => {
    setToggleProblemAreaOpen(false)
  }
  const closeProblemAreaPathway = () => {
    setToggleProblemAreaPathwayOpen(false)
  }
  const closeSubtype = () => {
    setToggleSubTypeOpen(false)
  }
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }
  const handleStoreClick = (store) => {
    // const selectedValue = event.target.value;
    setSelectedStore(history.push(store.to));
    console.log(selectedStore)
    window.location.reload();
    // if (selectedStore) {
    //   history.push(`${selectedStore}`);
    //   // window.location.reload();
    // }
  };

  return (
    <>

      <Container className="m-0 p-0">
        <Row className="m-0">
          <Col className="col-md-1 sidebar-margin">
            <div className="sidebar justify-content-center mt-5 pt-4">
              <ul style={{ listStyle: "none", padding: "4px", marginTop: '45px' }}>
                <li
                  className={
                    selectedOption === "dothings" ? "selected " : "inline "
                  }
                  onClick={() => {
                    handleOptionClick("dothings");
                    handleDoThings();
                  }}
                >
                  <img src={DoThingsIcon}
                  title="Home"
                    // icon={ faHome}
                    className={` mt-4 ${selectedOption === "dothings"
                        ? "store-overview-bar-icons-selected"
                        : "store-overview-bar-icons"}`
                    }
                  />
                  <p className="store-overview-icontext">Home</p>
                </li>
                <li
                  className={selectedOption === "trends" ? "selected" : ""}
                  onClick={() => {
                    handleOptionClick("trends");
                    handleStoreOverviewPage();
                  }}
                >
                  <img src={TrendsIcon}
                  title="Trends"
                    className={
                      `mt-4 ${selectedOption === "trends"
                        ? "store-overview-bar-icons-selected"
                        : "store-overview-bar-icons"}`
                    }
                  />
                  <p  className="store-overview-icontext">Trends</p>
                </li>

                <li
                  className={selectedOption === "products" ? "selected" : ""}
                  onClick={() => {
                    handleOptionClick("products");
                    handleProducts();
                  }}
                >
                  <img src={StoresIcon}
                  title="Products"
                    className={
                      ` mt-4 ${selectedOption === "products"
                        ? "store-overview-bar-icons-selected"
                        : "store-overview-bar-icons"}`
                    }
                  />
                  <p className="ml-1">Products</p>
                </li>
                <li
                  className={selectedOption === "reports" ? "selected" : ""}
                  onClick={() => {
                    handleOptionClick("reports");
                    handleReport();
                  }}
                >
                  {/* <img src={Reports_icon}  className={selectedOption === 'reports' ? 'store-overview-bar-icons-selected' : 'store-overview-bar-icons'} /> */}
                  <img src={ReportsIcon}
                  title="Reports"
                    className={
                      `mt-4 ${selectedOption === "reports"
                        ? "store-overview-bar-icons-selected"
                        : "store-overview-bar-icons"}`
                    }
                  />
                  <p className="ml-2">Reports</p>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="col-md-11 mt-5 p-0 ">
            {/* <Card  className='mt-5  card-of-problemarea'> */}
              <Row className='mt-1'>
                <Col md={12} className='mt-2'>
                <Row>
                  <Col md={6}>
                  <div className="d-flex mt-4 ml-4">
                    <p className="text-center mb-0 mr-3 ml-2">
                      <img
                        src={store.logo ? store.logo : Group}
                        width={store.logo ? "" : 40}
                      />
                    </p>
                    <h4 className=" mr-5"><b>{storeName}</b></h4>
                  </div>
                  <div className="mr-5 ml-4 mt-2">
                    <select
                      id="StoreList"
                      name="StoreList"
                      onChange={handleStoreChange}
                      value={selectedStore}
                      className="form-control w-50  ml-2"
                    >
                      <option value="">--Select Store--</option>
                      {menuItemsStoreList.length !== 0
                        ? menuItemsStoreList[0].content.map((store) => (
                          <option value={store.to}>{store.label}</option>
                        ))
                        : ""}
                    </select>
                  </div>
                  </Col>
                  <Col md={6}>
                  <Card className='p-3 card-of-problemarea mt-3  mr-3'>
                  <div className="d-flex ml-3 mt-2">
                    <p className="mb-0 ml-3"><b>Seller Partner Id: </b> {store.details.accountID} </p>
                    <p className="mb-0 ml-3"><b>Profile Id: </b> {store.integerID}</p>
                    <p className="mb-0 ml-3"><b>Location: </b> {store.location}</p>
                  </div>
                 
                  <div className="ml-2">
                    {store.ratingTotal < 1 ? (
                      <p className=" mb-0 ml-4 mt-2" >
                        No reviews yet
                      </p>

                    ) : (
                      <div className="d-flex ml-4  ">
                        <p className="text-center mt-2 ">
                          <StarRating
                            initialRating={store.rating}
                            readonly
                            stop={5}
                          />
                        </p>
                        <div className="text-center d-flex mb-0 mt-2 ">
                          <p className="mb-0 ml-1 mr-3">{store.rating}</p>

                          {`(${store.ratingTotal}`}
                          <p className="mb-0 ml-1 ">Reviews {')'}</p>
                          <span
                            className="positive-reviews ml-3"
                          // style={{ fontSize: "14px" }}
                          >
                            <b>{store.ratingTotalPercentage}% positive reviews</b>
                          </span>
                        </div>
                      </div>
                    )}</div>

                  </Card>
                 
                  </Col>
                </Row>
                
                 
                </Col>
              
              </Row>
            {/* </Card> */}
            <Row>
              <Col md={12}>
                {/* selectedOption === "products" */}
                {/* <div className="content ml-3 pl-0"> */}
                <div className={`content pl-0 ml-3`}>
                  <LoadingOverlay
                    active={props.storeLoading}
                    styles={{
                      overlay: (base) => ({
                        ...base,
                        background: "#f1f4f6",
                        opacity: 1,

                      }),
                      content: (base) => ({
                        ...base,
                        color: "#000",
                      }),
                    }}
                    spinner={<Loader active type="ball-pulse" />}
                    text="Loading store details..."
                  >
                    {/* {(props.status === "success" && homePage === true )
            ? rederStoreOverviewPage() :  doThings === true ?  renderDoThingspage()  : reportpage === true ? renderReportPage() : productsPage === true ? renderProductspage(): renderWaitMessage()} */}
                    {props.status === "success" &&
                      props.adProfiles.length > 0 &&
                      doThings === true
                      ? renderDoThingspage()
                      : props.adProfiles.length === 0
                        ? renderWaitMessage()
                        : homePage === true
                          ? rederStoreOverviewPage()
                          : reportpage === true
                            ? renderReportPage()
                            : productsPage === true
                              ? renderProductspage()
                              : renderWaitMessage()}
                  </LoadingOverlay>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    childProducts: state.accountOverview.store.childProducts,
    parentProducts: state.accountOverview.store.parentProducts,
    adProfiles: state.accountOverview.landing.adProfiles,
    // Power BI Reports
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport:
      state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport:
      state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    StoreOverviewPrimeReport: state.accountOverview.store.StoreOverviewPrimeReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport:
      state.accountOverview.store.CampaignPerformanceReport,

    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,

    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(StoreOverviewPage);
