import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import urls from "../../../../../../Utils/URLs";
import Arrow_Button from "./../../../../../../assets/img/StoreOverview_Img/Arrow_Button.svg";
import StoreOverviewDoThingsItems from "./StoreOverviewDoThingsItems";
import ArrowIcon_icon from "../../../../../../designUtils/Logo/Vector_Arrow.svg"
import Advertising_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Advertising.svg"
import SellerCentral_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/SellerCentral.svg"
import YourAccount_icon from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/YourAccount.svg"
import Solveproblems_icon from "../../../../../../designUtils/Storeoverview/Dothings/Solveproblems_icon.svg"

import BrandAccount from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/SellerCentral/BrandAccount.svg"
import Oprationalmanagment from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/SellerCentral/Oprationalmanagment.svg"

import Technicalissue from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/SimpliWorksAccount/Technicalissue.svg"
import AccountManagment from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/SimpliWorksAccount/AccountManagment.svg"

import CampainManagement from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/Advertising/CampainManagement.svg"
import GenralConsern from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/Advertising/GenralConsern.svg"
import Perfomancereporting from "../../../../../../designUtils/Storeoverview/SolveProblemIcon/Problempathway/Advertising/Perfomancereporting.svg"
import GetAllProblemAreaPathwaysService from "../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreaPathwaysService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "../.././../../../../designUtils/Logo/BackButton.svg"
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
function GetAllProblemAreaPathways(props) {
  const [problemAreaPathwayList, setProblemAreaPathwayList] = useState();
  const [problemAreaPathwayId, setProblemAreaPathwayId] = useState();
  const [problemAreaPathwayName, setProblemAreaPathwayName] = useState();
//   const [doThingItemPage, setDoThingItemPage] = useState(true);
//   const [ticketListPage, setTicketListPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  useEffect(() => {
    const data = {
      mainRequestTypeId: props.mainRequestTypeId,
      problemAreaId: props.problemAreaId,
    };
    const response = GetAllProblemAreaPathwaysService(props.token, data).then(
      (response) => {
        if (response) {
          setProblemAreaPathwayList(response);
        }
      }
    );
  }, []);

  const renderProblemAreaPathwayPage = () => {
    return (
    
      <Container fluid className='ml-3'>

      {/* <button
            onClick={()=>props.goBack()}
            style={{border: "none",background: "none"}}
            className="hover-btn-link"
          >
            Do Things 
          </button>/ */}
          {/* <button
            onClick={()=>props.goBackPA()}
            style={{border: "none",background: "none"}}
            className="hover-btn-link"
          >
            Solve Problems
            </button> */}
            {/* / {props.problemAreaName} */}
        <Row className="justify-content-between">
          <Col md={12}>
          <h5 className="mt-1">
            <button onClick={()=>{props.goBackPA();props.closeProblemArea()}} className="hover-btn-link back-btn"  >
            <img  className='m-2'src={BackButton} alt="not foun"/>
              </button>
             <img style={{height:40}} src={props.problemAreaName==='Seller Central'?SellerCentral_icon:props.problemAreaName==='Advertising'?Advertising_icon:props.problemAreaName==='Simpliworks Account'?YourAccount_icon:''} alt=" not found"/>  What type of {props.problemAreaName} Problems are you facing?  
          {/* <Button className="float-right btn_class">Get In Touch</Button>  */}
          </h5>
          </Col>
          {problemAreaPathwayList
              ? problemAreaPathwayList.map((pathway) => (
                <Col md={6} className='mb-3 ' >
                    <a href="#" className="text-decoration-none " onClick={()=>handleDoThingItemPage(pathway.id ,pathway.problemAreaPathway)}>
                    <Card className='card_design  doThingscardsolveprob1 '>
                    <Row className=" mt-1 p-2">
                    <Col md={3} className="doThingscardsolveprob" >
                     <img className="m-4 rounded mx-auto d-block "
                      src={pathway.problemAreaPathway==='Campaign Management'?CampainManagement:
                      pathway.problemAreaPathway==='Performance & Reporting' ?Perfomancereporting:
                      pathway.problemAreaPathway==='General Concerns'?GenralConsern:
                      pathway.problemAreaPathway==='Operational Management'?Oprationalmanagment:
                      pathway.problemAreaPathway==='Brand & Account Integrity'?BrandAccount:
                      pathway.problemAreaPathway==='Account Management'?AccountManagment:
                      pathway.problemAreaPathway=== 'Technical Issues'?Technicalissue:''}/>
                    {/* src={type.requestType =='SOLVE PROBLEMS' ? Solveproblems_icon :type.requestType == 'LAUNCH SOMETHING NEW' ? Launchsomthingnew_icon : type.requestType == 'BUILD YOUR BRAND' ? Buildyourbrands_icon: ''} alt="not found"/> */}
                    </Col>
                    <Col md={9} className="mt-4 " >
                    <h4 className="" style={{fontSize:"1.3rem"}}>{pathway.problemAreaPathway}</h4>
                        <p className={pathway.problemAreaPathway==='Campaign Management'?'mt-1':'mb-0'}>{pathway.helpText}</p>
                        <img  className='rounded float-right' src={ArrowIcon_icon} />
                    </Col>
                  </Row>
                <Row >
              <Col >
                   
            </Col>

          </Row>
                   
      </Card>
    </a>

  </Col>
                 
                ))
              : ""}
         
          </Row>
      </Container>
      
    );
  };
  const goBackPAP = () => {
  setCurrentPage(false);
  }
  const handleDoThingItemPage = (id, name) => 
  {
    props.SetCurrentOption(name,3)
    setProblemAreaPathwayId(id)
    setProblemAreaPathwayName(name)
    setCurrentPage(true);

  }
  const renderTicketListPage = () => {
    return (
      <>
      <StoreOverviewDoThingsItems SetCurrentOption ={props.SetCurrentOption} problemAreaName = {props.problemAreaName} goBack={props.goBack} goBackPA={props.goBackPA} goBackPAP={goBackPAP} mainRequestTypeId = {props.mainRequestTypeId} problemAreaId = {props.problemAreaId} problemAreaPathwayId={problemAreaPathwayId} problemAreaPathwayName={problemAreaPathwayName} closeProblemAreaPathway={props.closeProblemAreaPathway} closeSubtype={props.closeSubtype}/>
      </>
    );
  }
  return (
    
    <>
     <>
        <Row className="justify-content-between">
          <Col md={12} className='p-0'>
            {/* {doThingItemPage === true ? renderDoThingItemPage() : ticketListPage === true ? renderTicketListPage() :''} */}
            { currentPage ? renderTicketListPage() : renderProblemAreaPathwayPage() }
          </Col>
        </Row>
      </>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(GetAllProblemAreaPathways);
