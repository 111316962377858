import React, { Fragment, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import { Button, Col, Input, Label, Row } from 'reactstrap';
import CampaignLaunchProgressBar from '../../CampaignLaunch/Components/CampaignLaunchProgressBar';
import moment from 'moment';
import { CampaignLaunchStatus } from '../../../../../../../Service/OPSServices/CampaignLaunchServices';
import { connect } from 'react-redux';
import CustomModal from '../../../../../../Components/Modals/CustomModal';

function RecentCampaigns(props) {

  const formatDateAndTime = (isoString) => {
    return moment(isoString).format('YYYY-MM-DD hh:mm:ss');
  };
  const [campaignLaunchStatus, setCampaignLaunchStatus] = useState([])
  const [summary, setSummary] = useState();
  const [serachTeaxt, setSerachTeaxt] = useState('')
  const [isOpen, setIsOpen] = useState();
  const [dateRangeUi,setDateRange]=useState({startDate:'',endDate:''})
  const handleSearchGrid = (e) => {
    setSerachTeaxt(e.target.value)
  }
  useEffect(() => {

    const currentDate = new Date();

    const currentWeekStart = new Date(currentDate);
    currentWeekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1); // Adjusts for Monday as the start

    const lastWeekStart = new Date(currentWeekStart);
    lastWeekStart.setDate(currentWeekStart.getDate() - 7);

    const dateRange = {
      lastWeek: {
        startDate: lastWeekStart.toISOString().split('T')[0],
      },
    };

    let data = {
      startDate: dateRange.lastWeek.startDate,
      endDate: currentDate.toISOString().split('T')[0]
    };
    setDateRange({startDate : dateRange.lastWeek.startDate,
        endDate : currentDate.toISOString().split('T')[0]}
    )

    console.log('Data:', data);
    CampaignLaunchStatus(props.token, data).then(launchStatus => {
      if (launchStatus) {
        console.log(launchStatus)
        setCampaignLaunchStatus(launchStatus);
      }
    })

  }, [])
  const filterCampaignLaunchStatus = campaignLaunchStatus.filter((row) => {
    const storeName = (row.store || '').toLowerCase();
    const ASIN = (row.asin || '').toLowerCase();
    const SKU = (row.sku || '').toString().toLowerCase();
    return storeName.includes(serachTeaxt.toLowerCase()) || ASIN.includes(serachTeaxt.toLowerCase()) || SKU.includes(serachTeaxt.toLowerCase())
  })
  const getCompetitiveLandscapeSteps = (campaignStatus) => {
    const {
      initialKeywordsCollected,
      competitorsCollected,
      titleListGenerated,
      productInfoRetrieved,
      competitorKeywordsCollected,
      masterCorpusGenerated,
    } = campaignStatus;

    return {
      "Product Keywords": initialKeywordsCollected,
      "Competitors": competitorsCollected,
      "Generate Title List": titleListGenerated,
      "Product Listing": productInfoRetrieved,
      "Competitor Keywords": competitorKeywordsCollected,
      "Master Corpus": masterCorpusGenerated,
    }
  }
  const getCampaignGenerationSteps = (campaignStatus) => {
    const {
      marketCampaignGenerated,
      baseCampaignGenerated,
      amazonCampaignGenerated,
      productCampaignGenerated,
    } = campaignStatus;

    return {
      "Market Campaign": marketCampaignGenerated,
      "Base Campaign": baseCampaignGenerated,
      "Amazon Campaign": amazonCampaignGenerated,
      "Product Campaign": productCampaignGenerated,
    }
  }

  const getCampaignDeploymentSteps = (campaignStatus) => {
    const {
      marketCampaignDeployed,
      baseCampaignDeployed,
      amazonCampaignDeployed,
      productCampaignDeployed,
    } = campaignStatus;

    return {
      "Market Campaign": marketCampaignDeployed,
      "Base Campaign": baseCampaignDeployed,
      "Amazon Campaign": amazonCampaignDeployed,
      "Product Campaign": productCampaignDeployed,
    }
  }
  const columns = [
    {
      Header: 'Date',
      accessor: 'startTime',
      Cell: cellInfo => (
        <p>{formatDateAndTime(cellInfo.original.startTime)}</p>

      )
    },
    {
      Header: 'Store',
      accessor: 'store',
    },
    {
      Header: 'ASIN',
      accessor: 'asin',
    },
    {
      Header: 'SKU',
      accessor: 'sku',
    },
    {
      Header: 'Budget',
      accessor: 'budget',
      Cell: cellInfo => `$${cellInfo.value}`,
    },
    {
      Header: 'Competitive Landscape',
      Cell: cellInfo => {
        let steps = getCompetitiveLandscapeSteps(cellInfo.original);
        return (
          <div className="text-center" style={{ width: "100%" }}>
            <CampaignLaunchProgressBar
              stepNames={Object.keys(steps)}
              stepValues={Object.values(steps)}
              id={`competitiveLandscape_${cellInfo.original.campaignCreationId}`}
              header={"Competitive Landscape"}
            />
          </div>
        );
      }
    },
    {
      Header: 'Generate Campaigns',
      Cell: cellInfo => {
        let steps = getCampaignGenerationSteps(cellInfo.original);
        return (
          <div className="text-center" style={{ width: "100%" }}>
            <CampaignLaunchProgressBar
              stepNames={Object.keys(steps)}
              stepValues={Object.values(steps)}
              id={`campaignGeneration_${cellInfo.original.campaignCreationId}`}
              header={"Campaign Generation"}
            />
          </div>
        );
      }
    },
    {
      Header: 'Deploy Campaigns',
      Cell: cellInfo => {
        let steps = getCampaignDeploymentSteps(cellInfo.original);
        return (
          <div className="text-center" style={{ width: "100%" }}>
            <CampaignLaunchProgressBar
              stepNames={Object.keys(steps)}
              stepValues={Object.values(steps)}
              id={`campaignDeployment_${cellInfo.original.campaignCreationId}`}
              header={"Campaign Deployment"}
            />
          </div>
        );
      }
    },
    {
      Header: "Logs",
      accessor: "additionalInformation",
      Cell: cellInfo => (
        <Button
          color={"link"}
          onClick={() => {
            setSummary(cellInfo.value)
            setIsOpen(!isOpen)
          }}
        >
          View
        </Button>

      )
    },

  ];

  return (
    <>
      <Row>
        <Col md={12}>
        <div className='d-flex'>
        <Input
            type='text'
            placeholder='Search Text'
            className='w-25 mt-2 mb-3'
            value={serachTeaxt}
            onChange={(e) => handleSearchGrid(e)}
          />
          <Label className='ml-3 mt-3'><b>Date Range &nbsp; :&nbsp;</b> {dateRangeUi.startDate} &nbsp;&nbsp;<b>To &nbsp;:&nbsp;</b>{dateRangeUi.endDate}</Label>
        </div>
          
        </Col>
        <Col md={12}>
          <ReactTable
            data={filterCampaignLaunchStatus}
            columns={columns}
            getTheadProps={() => {
              return {
                style: {
                  overflowY: "hidden",
                  background: "#B1FA63",
                  // color: "#243837",
                },
              };
            }}
            defaultPageSize={25}
            noDataText={"No Data Found."}
          // defaultPageSize={5}
          />
        </Col>
      </Row>
      <CustomModal
        header={"Logs"}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        className='curve-popup'
        footer={(
          <>
            <Button
              color={"link"}
              onClick={() => setIsOpen(!isOpen)}
            >
              Cancel
            </Button>

          </>
        )}
      >
        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>
      </CustomModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(RecentCampaigns)
